<template>
  <div class="change-email">
    <van-nav-bar
      :title="$t('auth.changeDiagnosis.title')"
      fixed
      placeholder
    >
      <template #left>
        <van-icon
          :name="$icon('back')"
          size="16"
          color="#000"
          is-link
          @click="$router.back()"
        />
      </template>
    </van-nav-bar>
    <div class="container">
      <van-form
        validate-first
        @submit="submitForm"
      >
        <field-list>
          <van-cell
            :title="$t('diagnosis.title')"
            clickable
            :label="diagnosisLabel"
            @click="showDiagnosisPicker = true"
          />
          <van-field
            v-if="!isNutritionKeyProtein(diagnosis)"
            v-model.number="diagnosisValue"
            type="number"
            :label="diagnosis.getLabel()"
            :placeholder="$t('values.requirements.diagnosis', [
              $t(`nutritions.${diagnosis.nutritionKey}`),
            ])"
            :rules="[
              { required: true, message: $t('values.validation.required', [diagnosis.name]) },
              { validator: numberValidator, message: $t('values.validation.positive') },
            ]"
          />
          <van-checkbox
            v-if="diagnosis.optionalNutritionKeys"
            v-model="useOptionalNutritionValues"
          >
            {{ $t('auth.changeDiagnosis.optionalValues') }}
          </van-checkbox>
          <template v-if="useOptionalNutritionValues">
            <van-field
              v-for="nutritionKey in diagnosis.optionalNutritionKeys"
              :key="nutritionKey"
              v-model.number="optionalDiagnosisValues[nutritionKey]"
              type="number"
              :label="diagnosis.getLabel(true, nutritionKey)"
              :placeholder="$t('values.requirements.diagnosis', [
                $t(`nutritions.${nutritionKey}`),
              ])"
              :rules="[
                { validator: numberValidator, message: $t('values.validation.positive') },
              ]"
            />
          </template>
          <van-field
            v-model.number="nutritionLimits.protein"
            type="number"
            :label="`${$t('protein')} (g)`"
            :placeholder="$t('values.requirements.protein')"
            :rules="[
              {
                required: isNutritionKeyProtein(diagnosis),
                message: $t('values.validation.required', [$t('protein')]),
              },
              { validator: numberValidator, message: $t('values.validation.positive') },
            ]"
          />
          <van-field
            v-model.number="nutritionLimits.calories"
            type="number"
            :label="`${$t('calories')} (kcal)`"
            :placeholder="$t('values.requirements.calories')"
            :rules="[
              { required: true, message: $t('values.validation.required', [$t('calories')]) },
              { validator: numberValidator, message: $t('values.validation.positive') },
            ]"
          />
        </field-list>
        <p class="link-spacing">
          <a @click="showGuidelines = true">
            {{ $t('guidelines.link') }}
          </a>
        </p>
        <van-button
          type="primary"
          native-type="submit"
          block
          round
        >
          {{ $t('auth.changeDiagnosis.submit') }}
        </van-button>
        <van-popup
          v-model="showDiagnosisPicker"
          position="bottom"
        >
          <van-picker
            show-toolbar
            :title="$t('diagnosis.title')"
            :columns="diagnosisOptions"
            @confirm="selectDiagnosis"
            @cancel="showDiagnosisPicker = false"
          />
        </van-popup>
      </van-form>
    </div>
    <guidelines
      :show="showGuidelines"
      @hide="showGuidelines = false"
    />
  </div>
</template>

<script>
import { Notify } from 'vant';
import { auth } from '@/firebase';
import Diagnosis, { diagnoses } from '@/models/diagnosis';
import UserProfile from '@/models/user-profile';
import FieldList from '@/components/FieldList';
import Guidelines from '@/components/Guidelines/Guidelines.vue';
import { format } from 'date-fns';
import { isNutritionKeyProtein } from '@/helper';

export default {
  components: {
    FieldList,
    Guidelines,
  },
  data() {
    return {
      diagnosis: {},
      diagnosisName: '',
      nutritionLimits: {},
      diagnosisValue: 0,
      optionalDiagnosisValues: {},
      selectedDiagnosis: null,
      showDiagnosisPicker: false,
      showGuidelines: false,
      useOptionalNutritionValues: false,
    };
  },

  computed: {
    diagnosisLabel() {
      const item = diagnoses[this.selectedDiagnosis];
      return item ? `(${item.name}) ${item.title}` : 'Click to select a diagnosis';
    },

    diagnosisOptions() {
      return [
        {
          values: diagnoses.map((diagnosis) => `(${diagnosis.name}) ${diagnosis.title}`),
          defaultIndex: this.selectedDiagnosis,
        },
      ];
    },

    hasOptionalDiagnosisOptions() {
      return Object.keys(this.removeEmptyValues(this.optionalDiagnosisValues)).length;
    },
  },

  created() {
    this.nutritionLimits = this.$store.getters['user/nutritionLimits'];
    // Get the diagnosis index.
    this.diagnosis = new Diagnosis(this.$store.getters['user/diagnosisName']);
    this.selectedDiagnosis = diagnoses.findIndex((value) => this.diagnosis.name === value.name);
    this.diagnosisValue = this.nutritionLimits[this.diagnosis.nutritionKey] || null;

    if (this.diagnosis.optionalNutritionKeys) {
      this.diagnosis.optionalNutritionKeys.forEach((name) => {
        this.$set(this.optionalDiagnosisValues, name, this.nutritionLimits[name]);
      });
    }

    this.useOptionalNutritionValues = this.$store.getters['user/useOptionalNutritionValues']
      && this.hasOptionalDiagnosisOptions;
  },

  methods: {
    removeEmptyValues(object) {
      // eslint-disable-next-line no-unused-vars
      return Object.fromEntries(Object.entries(object).filter(([_, value]) => (typeof value === 'number' && !Number.isNaN(parseFloat(value)))));
    },

    numberValidator(value) {
      return value && Math.sign(value) === 1;
    },

    selectDiagnosis(_, index) {
      const [selectedDiagnosis] = index;

      this.selectedDiagnosis = selectedDiagnosis;
      this.showDiagnosisPicker = false;
      this.diagnosis = new Diagnosis(diagnoses[selectedDiagnosis].name);
      this.diagnosisValue = null;

      if (this.diagnosis.nutritionKey) {
        this.diagnosisValue = this.nutritionLimits[this.diagnosis.nutritionKey] || null;
      }
    },

    submitForm() {
      if (this.diagnosis.nutritionKey && !isNutritionKeyProtein(this.diagnosis)) {
        this.nutritionLimits[this.diagnosis.nutritionKey] = this.$helper
          .convertNumber(this.diagnosisValue);
      }

      if (this.useOptionalNutritionValues) {
        this.$store.commit('user/setOptionalNutritionValues', true);

        Object.entries(this.optionalDiagnosisValues).forEach(([name, value]) => {
          this.nutritionLimits[name] = this.$helper.convertNumber(value);
        });
      } else {
        // Remove unwanted additional nutritional values.
        this.$store.commit('user/setOptionalNutritionValues', false);

        Object.entries(this.optionalDiagnosisValues).forEach(([name]) => {
          delete this.nutritionLimits[name];
        });
      }

      const values = {
        key: format(Date.now(), 'yyyy-MM-dd'),
        nutritionLimits: this.removeEmptyValues(this.nutritionLimits),
        diagnosisName: this.diagnosis.name,
      };

      this.$store.commit('user/setDiagnosisName', this.diagnosis.name);

      new UserProfile(auth.currentUser.uid).get()
        .then((user) => {
          const { key, diagnosisName, nutritionLimits } = values;
          const newValues = { ...nutritionLimits, diagnosisName };
          const savedLimits = { ...user.savedNutritionLimits, [key]: { ...newValues } };

          new UserProfile(auth.currentUser.uid)
            .update({ savedNutritionLimits: savedLimits })
            .then(() => {
              Notify({
                type: 'primary',
                message: this.$i18n.t('auth.changeDiagnosis.success'),
                duration: 5000,
              });

              this.$router.push({ name: 'profile' });
            });
        });
    },
    isNutritionKeyProtein,
  },
};
</script>

<style lang="scss" scoped>
@use '~@/styles/config' as config;
.change-email__warning {
  margin: 2rem 0;
}
.link-spacing a {
  display: block;
  padding: 0.5rem 0 1rem;
}
</style>
