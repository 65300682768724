var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"change-email"},[_c('van-nav-bar',{attrs:{"title":_vm.$t('auth.changeDiagnosis.title'),"fixed":"","placeholder":""},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('van-icon',{attrs:{"name":_vm.$icon('back'),"size":"16","color":"#000","is-link":""},on:{"click":function($event){return _vm.$router.back()}}})]},proxy:true}])}),_c('div',{staticClass:"container"},[_c('van-form',{attrs:{"validate-first":""},on:{"submit":_vm.submitForm}},[_c('field-list',[_c('van-cell',{attrs:{"title":_vm.$t('diagnosis.title'),"clickable":"","label":_vm.diagnosisLabel},on:{"click":function($event){_vm.showDiagnosisPicker = true}}}),(!_vm.isNutritionKeyProtein(_vm.diagnosis))?_c('van-field',{attrs:{"type":"number","label":_vm.diagnosis.getLabel(),"placeholder":_vm.$t('values.requirements.diagnosis', [
            _vm.$t(`nutritions.${_vm.diagnosis.nutritionKey}`),
          ]),"rules":[
            { required: true, message: _vm.$t('values.validation.required', [_vm.diagnosis.name]) },
            { validator: _vm.numberValidator, message: _vm.$t('values.validation.positive') },
          ]},model:{value:(_vm.diagnosisValue),callback:function ($$v) {_vm.diagnosisValue=_vm._n($$v)},expression:"diagnosisValue"}}):_vm._e(),(_vm.diagnosis.optionalNutritionKeys)?_c('van-checkbox',{model:{value:(_vm.useOptionalNutritionValues),callback:function ($$v) {_vm.useOptionalNutritionValues=$$v},expression:"useOptionalNutritionValues"}},[_vm._v(" "+_vm._s(_vm.$t('auth.changeDiagnosis.optionalValues'))+" ")]):_vm._e(),(_vm.useOptionalNutritionValues)?_vm._l((_vm.diagnosis.optionalNutritionKeys),function(nutritionKey){return _c('van-field',{key:nutritionKey,attrs:{"type":"number","label":_vm.diagnosis.getLabel(true, nutritionKey),"placeholder":_vm.$t('values.requirements.diagnosis', [
              _vm.$t(`nutritions.${nutritionKey}`),
            ]),"rules":[
              { validator: _vm.numberValidator, message: _vm.$t('values.validation.positive') },
            ]},model:{value:(_vm.optionalDiagnosisValues[nutritionKey]),callback:function ($$v) {_vm.$set(_vm.optionalDiagnosisValues, nutritionKey, _vm._n($$v))},expression:"optionalDiagnosisValues[nutritionKey]"}})}):_vm._e(),_c('van-field',{attrs:{"type":"number","label":`${_vm.$t('protein')} (g)`,"placeholder":_vm.$t('values.requirements.protein'),"rules":[
            {
              required: _vm.isNutritionKeyProtein(_vm.diagnosis),
              message: _vm.$t('values.validation.required', [_vm.$t('protein')]),
            },
            { validator: _vm.numberValidator, message: _vm.$t('values.validation.positive') },
          ]},model:{value:(_vm.nutritionLimits.protein),callback:function ($$v) {_vm.$set(_vm.nutritionLimits, "protein", _vm._n($$v))},expression:"nutritionLimits.protein"}}),_c('van-field',{attrs:{"type":"number","label":`${_vm.$t('calories')} (kcal)`,"placeholder":_vm.$t('values.requirements.calories'),"rules":[
            { required: true, message: _vm.$t('values.validation.required', [_vm.$t('calories')]) },
            { validator: _vm.numberValidator, message: _vm.$t('values.validation.positive') },
          ]},model:{value:(_vm.nutritionLimits.calories),callback:function ($$v) {_vm.$set(_vm.nutritionLimits, "calories", _vm._n($$v))},expression:"nutritionLimits.calories"}})],2),_c('p',{staticClass:"link-spacing"},[_c('a',{on:{"click":function($event){_vm.showGuidelines = true}}},[_vm._v(" "+_vm._s(_vm.$t('guidelines.link'))+" ")])]),_c('van-button',{attrs:{"type":"primary","native-type":"submit","block":"","round":""}},[_vm._v(" "+_vm._s(_vm.$t('auth.changeDiagnosis.submit'))+" ")]),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showDiagnosisPicker),callback:function ($$v) {_vm.showDiagnosisPicker=$$v},expression:"showDiagnosisPicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","title":_vm.$t('diagnosis.title'),"columns":_vm.diagnosisOptions},on:{"confirm":_vm.selectDiagnosis,"cancel":function($event){_vm.showDiagnosisPicker = false}}})],1)],1)],1),_c('guidelines',{attrs:{"show":_vm.showGuidelines},on:{"hide":function($event){_vm.showGuidelines = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }